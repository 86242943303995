import { ref, readonly } from "vue";

const colors: Record<PrintColors, string> = {
  primary: "#FFFFFF",
  "on-primary": "#212121",
  secondary: "#212121",
  "on-secondary": "#FFFFFF",
  border: "#01010133",
} as const;

const printColors = ref<typeof colors>(colors);

const setPrintColors = (newColors: typeof colors) => {
  printColors.value = newColors;
};
const clearPrintColors = () => {
  printColors.value = colors;
};

const buildQrCodeArgs = (data: {
  orgName: string;
  orgTaxNumber: string;
  invoiceCreatedAt: string;
  invoiceIssueDate?: string | null;
  prices: {
    actualPriceSubtotal: number;
    taxSubtotal: number;
  }[];
}) => {
  const createdDateTime = data.invoiceCreatedAt.split("T");
  const concatenatedTimestamp = data.invoiceIssueDate
    ? data.invoiceIssueDate.split("T")[0] + "T" + createdDateTime[1]
    : undefined;

  const fullTotal =
    data.prices.reduce(
      (acc, curr) => acc + curr.actualPriceSubtotal + curr.taxSubtotal,
      0
    ) / 100;

  const vatTotal =
    data.prices.reduce((acc, curr) => acc + curr.taxSubtotal, 0) / 100;

  if (
    data.orgName === undefined ||
    data.orgTaxNumber === undefined ||
    concatenatedTimestamp === undefined
  ) {
    return;
  }

  return [
    data.orgName,
    data.orgTaxNumber,
    concatenatedTimestamp,
    fullTotal.toFixed(2),
    vatTotal.toFixed(2),
  ];
};

export function usePrintSettings() {
  return {
    printColors: readonly(printColors),
    // setters
    setPrintColors,
    buildQrCodeArgs,
    // clears
    clearPrintColors,
  };
}
